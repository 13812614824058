import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';

const AboutSection = styled.div`
  position: relative;
  padding: 70px 0 50px;
  color: #fff;
  z-index: 2;
  margin-bottom: 75px;

  @media (min-width: 786px) {
  margin-bottom: 150px;
  }
  
  &::after,
  &::before {
    content: "";
    position: absolute;
    right: -100px;
    top: 0;
    bottom: 0;
    left: -100px;
    z-index: -1;
  }
  &::before {
    transform: rotate(5deg);
    background: var(--sycamore);
    
  }  
  &::after {
    transform: rotate(-5deg);
    background: rgba(159, 167, 64, 0.2);
    mix-blend-mode: multiply;
    transform: matrix(-1, 0.09, 0.09, 1, 0, 0);
  }
`;

const Centered = styled.p`
  text-align: center;
  position: relative;
`;

const EpisodesHeading = styled.h2`
  font-size: 36px;
  color: var(--sycamore);
  font-weight: 900;

  @media (min-width: 786px) {
    font-size: 48px;
  }
`;

const EpisodesList = styled.ul`
  padding: 0;
  list-style: none;
  margin-bottom: 100px;
`;

const EpisodesListItem = styled.li`
  margin-bottom: 40px;
`;

const EpisodeDetails = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const EpisodeTitle = styled.h3`
  font-size: 26px;
  margin-bottom: 15px;
`;

const EpsiodeDate = styled.span`
  font-size: 14px;
  font-family: "Open Sans";
  text-transform: uppercase;
  font-weight: 600;
  display: block;
  margin-top: 8px;
`;

function getEpisodeID(id) {
  return id.split('__')[2];
}

function formatDate(date) {
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ];
  const originalDate = new Date(date);
  return `${originalDate.getDate()} ${monthNames[originalDate.getMonth()]} ${originalDate.getFullYear()}`;
}

const IndexPage = ({
  data: {
    allBuzzsproutPodcastEpisode: { edges: episodes },
  },
}) => (
  <Layout>
    <SEO title="The Good Tree Podcast" />
    <AboutSection>
      <div className="spring">
        <div className="row">
          <div className="col-md-3 offset-md-1">
            <h2 className="content-light">
              About the podcast
            </h2>
            <p>
              <strong>The Good Tree</strong>
              {' '}
              podcast is a weekly discussion with renowned scholars deep diving into a range of ethical topics. We begin the first series on relationships.
            </p>
            <p>Allah (swt) directs us to look at Prophet Muhammad (saw) as our moral guide in order to gain closeness to Him (swt). </p>
          </div>
          <div className="col-md-4">
            <p>Our name ‘The Good Tree’ comes from the Holy Qur’an, Chapter 14 Surah Ibrahim, verses 24 and 25:</p>
            <Centered>
              <em>
                Have you not considered how Allah presents an example, [making] a good word like a good tree, whose root is firmly fixed and its branches [high] in the sky?
                {' '}
                <cite>[14:24]</cite>
              </em>
            </Centered>
            <Centered>
              <em>
                It produces its fruit all the time, by permission of its Lord. And Allah presents examples for the people that perhaps they will be reminder.
                {' '}
                <cite>[14:25]</cite>
              </em>
            </Centered>
            <p>
              We hope you will enjoy listening to these podcasts and find them beneficial on your journey towards Allah (swt).
            </p>
          </div>
          <div className="col-md-3">
            <h2 className="content-light">About the hosts</h2>
            <p>
              <strong>Fizza Hasan</strong>
              {' '}
              - is a teacher with a passion for creating platforms for change and reform in the community. South of the river but still loves Noor.
            </p>
            <p>
              <strong>Noor Owainati</strong>
              {' '}
              - is an Islamic Seminary student who believes words create revolutions within souls and within communities. North of the river but still loves Fizza.
            </p>
          </div>
        </div>
      </div>
    </AboutSection>
    <div className="spring">
      <EpisodesHeading>Episodes</EpisodesHeading>
      <EpisodesList>
        {episodes.map(({ node }) => ((
          new Date(node.published_at) < new Date())
          ? (
            <EpisodesListItem key={node.id}>

              <div className="row">
                <div className="col-md-7 order-2 order-md-1">
                  <iframe src={`https://www.buzzsprout.com/1433479/${getEpisodeID(node.id)}?client_source=small_player&amp;iframe=true&player=small`} width="100%" height="200" frameBorder="0" scrolling="no" title={node.title} />
                </div>
                <div className="col-md-5 order-1 order-md-2">
                  <EpisodeDetails>
                    <EpisodeTitle>
                      {node.title}
                      <EpsiodeDate>{formatDate(node.published_at)}</EpsiodeDate>
                    </EpisodeTitle>
                    <p dangerouslySetInnerHTML={{ __html: node.summary }} />
                  </EpisodeDetails>
                </div>
              </div>
            </EpisodesListItem>
          )
          : null))}
      </EpisodesList>
    </div>
  </Layout>
);

IndexPage.defaultProps = {
  data: {},
};

IndexPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.object),
};

export const query = graphql`
  query PodcastPageQuery {
    allBuzzsproutPodcastEpisode {
      edges {
        node {
          id
          title
          description
          audio_url
          published_at
          summary
        }
      }
    }
  }
`;

export default IndexPage;
